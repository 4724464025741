.header_main_container {
    display: flex;
    justify-content: space-between;
    max-height: 60px;
    border-bottom: 1px solid lightgrey;
}

.header_left {
    display: flex;
    align-items: center;
}

.header_left ul {
    display: flex;
    list-style-type: none;
    gap: 0.3rem;
    padding-left: 20px !important;
}

.header_left ul li {
    font-size: 1rem;
    cursor: pointer;
    position: relative;
    padding: 10px 8px;
    font-weight: 600;
    color: var(--Grey2);
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 15px;
}

.header_left ul li.selected {
    background: #e0f0f2;
    color: var(--Secondary-green--dark);
    border-radius: 6px;
}

.header_left ul li:hover {
    background: #e0f0f2;
    color: var(--Secondary-green--dark);
    border-radius: 6px;
}

.header_right {
    display: flex;
    align-items: center;
    padding-right: 1rem;
}

.Header_section ul {
    display: flex;
    list-style-type: none;
    gap: 0.5rem;
}

.Header_section ul li {
    font-size: 1rem;
    position: relative;
    padding: 6px 2px;
    font-weight: 500;
    color: var(--Grey2);
    border: 1px solid var(--Grey2);
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 15px;
    cursor: pointer;
}

/* .Header_section ul li:hover {
    color: #e0f0f2;
    background: var(--Secondary-green--dark);
    border-radius: 6px;
} */

.search_box {
    margin-right: 20px;
}

.clinic_profile_circle {
    width: 40px;
    height: 40px;
    background: var(--Secondary-green--light);
    color: var(--Secondary-green--dark);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 50%;
    transition: 0.2s ease all;
}

.clinic_profile_circle:hover {
    box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
}


/***  Popover styles ***/
.clinicHeader__popover {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
}

.clinicHeader__popover--name {
    font-weight: 600;
    white-space: nowrap;
}

.clinicHeader__popover--list {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 0.4rem;
    color: var(--color-grey-700);
}

.clinicHeader__popover--list div {
    cursor: pointer;
}

.clinicHeader__popover--list .logout {
    color: var(--Secondary-green--dark);
}

.popover_container {
    display: flex;
}