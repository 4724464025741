.notes_dialog .MuiPaper-rounded {
  border: 3px solid #7f7f7f;
  border-radius: 20px;
  height: 100%;
}

.notes_container {
  width: 384px;
  height: 100%;
  background-color: #fff;
  overflow-x: hidden;
}

.header_section {
  padding: 0.5rem 0.5rem 0 0.5rem;
  border-bottom: 1px solid #c7c7c7;
  background-color: white;
  position: sticky;
  top: 0;
}

.close_btn {
  cursor: pointer;
  color: grey;
}

.header_section_one {
  display: flex;
  justify-content: space-between;
}

.header_section_one_text {
  border-bottom: 1px solid;
}

.header_section_two {
  padding: 1rem 1rem 0.1rem 0.5rem;
  border-top: 1px solid #c7c7c7;
}

.detailed_section_dec {
  display: flex;
  gap: 5px;
  padding: 3px;
}

.notes_section {
  padding: 1rem;
  border-bottom: 1px solid #c7c7c7;
}

.notes_section_div {
  display: flex;
  margin-bottom: 5px;
}

.body_container {
  margin-bottom: 110px;
}

.comment_replay_section {
  width: 100%;
  position: absolute;
  bottom: 0;
  padding-bottom: 10px;
  background-color: #fff;
}

.comment_fields {
  display: flex;
  margin: 0 5px;
}

.comment_fields > input {
  border: none;
  width: 335px;
  outline: none;
  background: #e7f7f4;
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 12px 0 0 12px;
}

.file_uploader {
  display: flex;
  gap: 5px;
  padding: 0.3rem;
  overflow-y: auto;
}

.file_uploader_preview {
  padding: 0.1rem;
  background: #f1f3f4;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.3rem;
  border: 1px solid var(--Grey5);
  font-size: 0.8em;
  position: relative;
}

.file_uploader_preview .close-icon-rounded {
  cursor: pointer;
}

.file_uploader_textContent {
  display: flex;
  flex-direction: column;
}
.file_uploader_textContent > .name {
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file_uploader_textContent > .type {
  color: var(--Grey3);
}

.comment span {
  color: #14a6a6;
  text-decoration: underline;
  cursor: pointer;
}

.description {
  max-height: 150px;
  overflow-y: auto;
}
