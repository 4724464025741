.ticket_filter_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ticket_pagination_container {
    padding-top: 10px;
    padding-left: 3px;
}

.ticket_filters {
    display: flex;
    gap: 16px;
    padding-right: 20px;
    padding-bottom: 5px;
}

.ticket_filters_dropdown {
    width: 80px;
}

.ticket_filters_date {
    width: 110px;
}

.ticket_filters_btn {
    padding-top: 12px;
}

.ticket_table_main_container {
    overflow-y: auto;
}

.ticket_table_head_cell {
    background-color: #e7f7f4 !important;
    white-space: nowrap;
}

.ticket_table_head_cell.priority {
    width: 45px;
}

.ticket_table_head_cell.check {
    width: 45px;
}

.ticket_table_head_cell.MuiTableCell-sizeSmall {
    padding: 0px 0px 0px 8px !important;
}

.ticket_table_body_cell.MuiTableCell-sizeSmall {
    padding: 5px 0px 5px 8px !important;
}

.ticket_table_body_cell {
    white-space: nowrap;
}

.ticket_table_body_cell.ticket {
    width: 50px;
}

.ticket_table_body_cell.owner {
    /* width: 100px; */
}

.ticket_table_body_cell.status {
    /* width: 100px; */
}

.ticket_table_body_cell.ticket.MuiTableCell-sizeSmall {
    padding: 5px 8px 5px 20px !important;
}

.ticket_table_body_cell.check {
    padding: 7px 0px 7px 8px !important;
}

.ticket_table_body_cell.clinic {
    /* width: 145px; */
}

.ticket_table_body_cell.type {
    /* width: 100px; */
}

.priority_icon_high {
    color: red;
}

.checkBox_priority_cell {
    display: flex;
    align-items: center;
}

.table_editBtn {
    margin-right: 8px;
    cursor: pointer;
}

.add_new_ticket {
    position: fixed;
    top: 83%;
    left: 94%;
    cursor: pointer;
}

.owner_update_dropdown.MuiInput-formControl {
    margin-top: 0;
    font-size: 14px;
}

.type_update_dropdown.MuiInput-formControl {
    margin-top: 0;
    font-size: 14px;
}

.status_update_dropdown.MuiInput-formControl {
    margin-top: 0;
    font-size: 14px;
}

.noDataFound {
    height: 675px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bulk_update {
    display: flex;
    gap: 8px;
}

.bulk_update_dropdown {
    width: 80px;
}

.bulk_update_btn {
    padding-top: 12px;
}

.ticket_pagination .MuiTablePagination-selectRoot {
    margin-left: 4px;
    margin-right: 10px;
}

.ticket_pagination .MuiTablePagination-actions {
    margin-left: 4px;
}

.ticket_pagination .MuiIconButton-root {
    padding: 2px;
}