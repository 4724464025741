.task_filter_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.task_pagination_container {
    padding-top: 10px;
    padding-left: 3px;
}

.task_filters {
    display: flex;
    gap: 16px;
    padding-right: 20px;
    padding-bottom: 5px;
}

.task_filters_dropdown {
    min-width: 90px;
    max-width: 90px;
}

.task_filters_date {
    max-width: 110px;
}

.task_filters_btn {
    padding-top: 12px;
}

.task_table_main_container {
    overflow-y: auto;
}

.task_table_head_cell {
    background-color: #e7f7f4 !important;
    white-space: nowrap;
}

.task_table_head_cell.priority {
    width: 45px;
}

.task_table_head_cell.check {
    width: 45px;
}

.task_table_head_cell.MuiTableCell-sizeSmall {
    padding: 0px 0px 0px 8px !important;
}

.task_table_body_cell.MuiTableCell-sizeSmall {
    padding: 5px 0px 5px 8px !important;
}

.task_table_body_cell {
    white-space: nowrap;
}

.task_table_body_cell.subject {
    /* width: 250px; */
}

/* .task_table_body_cell.check {
    width: 20px;
} */

.task_table_body_cell.ticket {
    width: 80px;
}

.task_table_body_cell.ticket.MuiTableCell-sizeSmall {
    padding: 5px 8px 5px 20px !important;
}

.task_table_body_cell.check {
    padding: 7px 0px 7px 8px !important;
}

.task_table_body_cell.clinic {
    /* width: 145px; */
}

.task_table_body_cell.type {
    /* width: 100px; */
}

.task_table_body_cell.owner {
    /* width: 80px; */
}

.task_table_body_cell.status {
    /* width: 80px; */
}

.table_editBtn {
    margin-right: 8px;
    cursor: pointer;
}

.add_new_task {
    position: fixed;
    top: 83%;
    left: 94%;
    cursor: pointer;
}

.owner_dropdown.MuiInput-formControl {
    margin-top: 0;
    font-size: 14px;
}

.type_dropdown.MuiInput-formControl {
    margin-top: 0;
    font-size: 14px;
}

.status_dropdown.MuiInput-formControl {
    margin-top: 0;
    font-size: 14px;
}

.noDataFound {
    height: 675px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bulk_update {
    display: flex;
    gap: 8px;
}

.bulk_update_dropdown {
    width: 80px;
}

.bulk_update_btn {
    padding-top: 12px;
}

.task_pagination .MuiTablePagination-selectRoot {
    margin-left: 4px;
    margin-right: 10px;
}

.task_pagination .MuiTablePagination-actions {
    margin-left: 4px;
}

.task_pagination .MuiIconButton-root {
    padding: 2px;
}