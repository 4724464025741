.ticket_create_dialog {
    border-radius: 8px;
}

.ticket_create_container {
    padding: 2rem 3rem;
}

.close_icon {
    width: 100%;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.newTicketBtn {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.text_area:focus {
    min-height: 35px;
}

.des_error_message {
    font-size: 0.8rem;
    color: red;
}